// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #f16722;
$body-bg:               #fff !default;
$gray-dark:             #666;
$text-color:            $gray-dark !default;
$brand-dark: #3a3a49;
$brand-light: #92b7bd;
$link-color:            $brand-primary;
$header-color: darken($brand-light,20%);
$border-radius-base: 0;

// Fonts
$font-size-base: 14px;
$font-family-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$headerFont: 'Open Sans Condensed', "Helvetica Neue", Helvetica, Arial, sans-serif;
$line-height-base:       30/18;
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px
$headings-font-weight:    300 !default;

$font-size-base2: 16px;

$font-size-h1:            floor(($font-size-base2 * 2)) !default; // ~36px
$font-size-h2:            floor(($font-size-base2 * 1.8)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base2 * 1.6)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base2 * 1.3)) !default; // ~18px
$font-size-h5:            ceil(($font-size-base2 * 1.2)) !default;
$font-size-h6:            $font-size-base2 !default;

$footer-height: 50px;
$adminbar-height: 32px;

// navbar
$navbar-height:                    100px !default;
$navbar-margin-bottom:             $line-height-computed !default;
$navbar-border-radius:             0;
$navbar-padding-horizontal:        0px;
$navbar-padding-vertical:          10px;
$navbar-collapse-max-height:       340px !default;

$navbar-default-color:             #fff;
$navbar-default-bg:                transparent;
$navbar-default-border:            none;

// Navbar links
$navbar-default-link-color:                #fff !default;
$navbar-default-link-hover-color:          #fff !default;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         #fff !default;
$navbar-default-link-active-bg:            transparent;
$navbar-default-link-disabled-color:       #ccc !default;


// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd !default;
$navbar-default-toggle-icon-bar-bg:        #888 !default;
$navbar-default-toggle-border-color:       #ddd !default;

$grid-gutter-width:         30px !default;
$container-desktop:            (940px + $grid-gutter-width) !default;
$container-md:                 $container-desktop !default;

$container-large-desktop:      (1000px + $grid-gutter-width) !default;
$container-lg:                 $container-large-desktop !default;