﻿h1.block-content-headline,
h2.block-content-headline {
    padding: 10px 0px;
    text-align: left;

    line-height: 1;
    margin: 10px 0 0px;
    small {
        color: $gray;
        margin: 1em 0;
        line-height: 1.1;
        font-size: $font-size-base * 2.3;
    }

}

.main .block-content,
.block-content {
    margin: 30px 0;
    clear: both;
    h3 {
        /*margin-top: 50px;*/
    }
}

.block-content-text {
    background: #fff;
    padding: 0;
    margin: 30px 0 0px;

    > h1:first-child,
    > h2:first-child,
    > h3:first-child {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.main .block.block-welcome {
    font-size: 150%;
    padding-bottom: 0px;
    margin-bottom: 0px;
    h2.block-content-headline {
        margin-bottom: 0.8em;
    }
}

.block-content-featured-therapist {
    background: #eee;
    padding: 10px 0px;
    h2 {
        font-size: 160%;

        small {
            color: #666;
            font-size: 90%;
        }
    }
}
.block-content-callouts  {
    background: #eee;
    .feature {

        .feature-description {
            padding: 0 20px 20px;
        }
    }
}

@media(max-width: $screen-sm-max) {
    h2.block-content-headline {
        font-size: $font-size-base * 2;
    }
}