.menu-footer-social {
    float: right;
    list-style: none;
    font-size: 32px;
    line-height: 32px;
    margin: 0px 0 -5px;
    padding: 0;

    > li {
        display: inline-block;
        > a {
            display: inline-block;
            overflow: hidden;
            padding: 0 10px;

            &:hover {
                opacity: 0.8;
            }
        }

    }
}

.nav-footer {
    float: right;
}
.content-info {
    background: $brand-light;
    color: #fff;
    padding: 15px 0;
    font-size: 14px;
    margin-top: -55px;
    min-height: 55px;
    position: relative;
    z-index: 10;

    width: 100%;

    a {
        color: #fff;
        text-decoration: none;
        :hover {
            color: $text-color;
        }
    }
    .menu-footer-nav {
        list-style: none;
        padding: 0;
        margin: 0;
        float:left;
        li {
            display: inline-block;
            padding: 0;
            margin: 0;
            a {
                display: inline-block;
                padding: 0 10px;
            }
        }
    }
    .copyright {
        float: none;
        padding: 0 40px 0 0;
    }
}