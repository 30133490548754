// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.btn, .comment-form input[type=submit], .featured-news .read-more, .read-more, .search-form .search-submit, button, input[type=submit] {
  @extend .btn;
  @extend .btn-primary;
}


.gfield {
    position: relative;
}

input[type=text],
input[type=url],
input[type=email],
input[type=tel],
input[type=number],
input[type=password],
select,
textarea {
    background: #eee;
    /*padding-top: 10px;*/
    border: #ccc;
    padding: 5px;
    /*padding-left: 10px;
    pading-right: 10px;*/
    border-radius: 0;

}

label,
.gform_wrapper .ginput_complex label,
body .gform_wrapper .top_label .gfield_label,
.gfield_label {
    font-weight: bolder;
    text-transform: none;
    color: inherit;
    margin-bottom: 0;
    margin-top: 1em;
}

.gform_wrapper  {
    margin: 0 0 50px!important;

    .gform_fields {
        .gsection {
            margin-bottom: 0px;
            border: none;
            margin-top: 40px;
            padding-bottom: 0;
            &:first-child {
                margin-top: 0;
            }
        }
        .gsection_description {
            margin-top: 8px;
            margin-bottom: 0;
        }
    }


    h2.gsection_title {
        margin-bottom: 0;
        font-size: 28px;
        font-weight: 300;
        color: $text-color;
        font-family: $headerFont;
    }

    .ginput_complex label {
        opacity: 0.75;
        text-transform: none;
        margin-top: 0;
    }

    .gform_footer {
        padding: 0 2.5% 0 0 !important;
    }
    .gform_page_footer {
        border-top: none;
    }

    .gsection_description {
        font-size: 1em;
    }


    .gform_page_footer .button.gform_next_button,
    .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit] {
        background: $brand-primary;
        border: $brand-primary;
        color: #fff;
        text-transform: none;
        /*font-family: $headerFont;*/
        padding: 6px 30px;
        /*font-size: $font-size-base * 1.5;*/
    }
    .gform_page_footer .button.gform_previous_button {
        text-transform: none;
        /*font-family: $headerFont;*/
        padding: 6px 30px;
        /*font-size: $font-size-base * 1.5;*/
        border: none;
    }


    .gf_page_steps {
        border: none;
    }

    .gf_step {
        width: auto !important;
        margin: 0 1em 0em 0;
        padding: 5px;
        font-size: 1em;
        height: 2em;
        line-height: 1.25 !important;
        filter: alpha(opacity=40);
        opacity: .4;
        font-family: $font-family-sans-serif;
        display: -moz-inline-stack;
        display: inline-block;

        span.gf_step_number {
            font-family: $font-family-sans-serif;
            padding-right: 5px;
        }
        .gf_step_label  {
            line-height: 1.5;
        }


    }

    .gfield_checkbox li label,
    .gfield_radio li label {
        font-weight: 300;
    }
    ul.gfield_radio li input[type="radio"]:checked+label,
    ul.gfield_checkbox li input[type="checkbox"]:checked+label {
        font-weight: 300;
    }

    table.gfield_list thead th {
        font-weight: bolder;
    }
}

#wpmem_login {

    legend {
        display: none;
    }
    input[type=text],input[type=password] {
        max-width: 100%;
        width: 300px;
    }
    .button_div {
        padding-top: 10px;
    }
    input[type=submit] {
        display: block;
        /*width: 10em;*/
        margin-top: 10px;
    }
}

/* name suffix */
#input_4_9_8_container {
    display: none;
}


.ginput_complex.ginput_container.ginput_container_password .ginput_left {
    padding-right: 0 !important;
}
.ginput_complex.ginput_container.ginput_container_password .ginput_right {
    clear: left !important;
    display: block;
}

.ginput_container_slim > .slim {
    overflow: hidden;
}