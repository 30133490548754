// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  /*.sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }*/
}
.sidebar {
  /*@include make-sm-column($sidebar-sm-columns);*/
  z-index: 100000;
  position: fixed;
  width: 400px;
  left: -400px;
  top: 60px;
  bottom: 50px;
  transition: 1s;
  &.expand {
    transition: 1s;
    left: 0;
  }
  background: rgba(255,255,255,0.95);
  padding: 20px 0;
  .widget {
    margin: 10px 40px;
  }
}
.admin-bar .sidebar {
    top: 92px;
}

@media(max-width: $screen-sm-max){
    .container {
        width: 100%;
    }
}