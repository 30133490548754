html, body {margin: 0; padding: 0; overflow-x:hidden;}
body {
    position: relative;
    font-weight: 300;
    background: #fff;

    b, strong {
        font-weight: 400;
    }
    padding-bottom: 0 !important;
}
body#tinymce {
    background: #fff;
    font-size: 16px;
}

h2 a:link,
h2 a:visited {
    color: $brand-primary;
}

.wrap {
    min-height: 100vh;
    z-index: 0;
    position: relative;

    .wrap-spacer {
        display: block;
        padding-top: $navbar-height + 7px;
        @media(max-width: $screen-md-max) {
            padding-top: 92px;
        }
    }
    .wrap-spacer-bottom {
        display: block;
        height: 55px;
    }
}

.CTA {
    background: $brand-dark;
    text-align: center;
    padding: 30px 0 30px;
    margin-bottom: 0;
    /*margin-top: -50px;*/
    h2 {
        color: #fff;
        font-size: 32px;
        line-height: 1.1;
        margin: 0 0 1em;
        small {
            font-size: 30px;
            font-weight: 300;
            margin: 0.75em 0;
        }
    }
}

.page-header {
    h1, h2, h3 {
        text-transform: none;
        margin: 0;
        color: $header-color;
    }
    margin: 1m 0 1em;
    border-bottom: none;
    padding: 0;
    small {
        display: block;
        text-transform: none;
        color: inherit;
        line-height: 1.5;
        font-size: 50%;
    }
}
.page-header h1 {
    color: $brand-dark !important;
}
.home .page-header h1 {
    color: $header-color !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $headerFont;
    font-weight: 600;
    color: $header-color;
    h1.block-content-headline,

    small {
        color: $gray;
        display: block;
        margin: 1em 0;
        line-height: 1.1;
        font-size: 80%;
    }


}
/*h1, h2{
    color: $brand-dark;
}*/
h3  {
    color: inherit;
}
h4 {
    text-transform: none;
    color: inherit;
    margin-bottom: 0.5em;
    margin-top: 1em;
}
h3 {
    line-height:1.25;
}
h5 {
    color: $brand_primary;
}

blockquote {
    border-left: none;
    font-size: $font-size-base;
    margin: 0;
    padding-left: 10px 40px;
    position: relative;
    p:before {
        font-size: 30px;
        margin-left: -40px;
        margin-top: -15px;
        display: block;
        position: absolute;
        font-family: FontAwesome;
        content: "\f10d";
        opacity: 0.7;
        width: 1em;
        height: 1em;
    }
    p:after {
        font-size: 30px;
        margin-top: -1em;
        display: block;
        position: absolute;
        right: 0;
        font-family: FontAwesome;
        content: "\f10e";
        opacity: 0.7;
        width: 1em;
        height: 1em;
    }
    footer {
        font-size: $font-size-base;
    }
}

address {
    color: rgb(85,87,92);
}

.map {
    height: 300px;
    box-shadow: inset 0px 4px 7px -2px rgba(0,0,0,0.25);
    margin-top: 20px;
    .gm-style {
        box-shadow: inset 0px 4px 7px -2px rgba(0,0,0,0.25);
    }
}

.fullwidth {
    padding: 0;
    margin: 0;
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
}

// full screen video
.fullscreen-bg {
    position: fixed;
    top: $navbar-height;
    right: 0;
    bottom: $footer-height;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

.admin-bar .fullscreen-bg {
    top: ($navbar-height + $adminbar-height);
}

.fullscreen-bg__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.section-spacer {
    padding: 0 0 0px;
    height: 1px;
    position: relative;
    a {
        position: relative;
        top: 0px;

        /* margin-bottom: -50px; */
        display: block;
    }
}

.attachment-sideimage  {
    max-width: 100%;
    height: auto;
}

.btn {
    border-radius: 0;
}
.btn-sm {
    text-transform: uppercase;
    line-height: 1;
    padding: 2px 5px;
}
#btn-recent-updates {
    position: absolute;
    left: 100%;
    top: 0;
    max-width: 7em;
    white-space: normal;
}

.main {
    background: #fefefe;
    margin-bottom: 40px;
    font-size: 15px;

    .block {
        ol, ul {
            padding-left: 30px;
        }
        ul:not(.dropdown-menu,.page-numbers,.gform_fields,.pagination) {
            li {
                list-style: none;
                margin: 0.2em 0;

                &:before {
                  content: '\f00c'; /* fa-star */
                  font-family: 'FontAwesome';
                  float: left;
                  margin-left: -1.5em;
                  color: inherit; /*$brand-light;*/
                  opacity: 0.8;
                }
            }
        }
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0px;
        }

    }

}

/*.block-resources-row.container,
.page-header > .container,
.main > .container {
    max-width: 900px;
}*/

.accordion-item {
    background: #fafafa;
    .accordion-content {
        padding: 0px 40px 20px !important;

        > h2:first-child {
            margin-top: 30px;
            padding-top: 0;
        }
    }
}
.accordion-link {
    background: #f2f2f2;
    color: $brand-dark!important;
    padding: 10px 20px !important;
    margin: 2px 0;
    display: block;
    font-size: 120%;
    text-decoration: none!important;
    .visible-collapsed {
        display: inline-block;
        margin-right: 7px;
    }
    .visible-expanded {
        display: none;
    }
}
.accordion-link[aria-expanded=true] {
    .visible-collapsed {
        display: none;
    }
    .visible-expanded {
        display: inline-block;
        margin-right: 7px;
    }
}

.accordion-item .collapse {
    padding-bottom: 40px;
}

.ul-columns {
    @include make-row;

    &.ul-columns-2 .ul-column {
        @include make-sm-column(6);
    }
    &.ul-columns-3 .ul-column {
        @include make-sm-column(4);
    }
    &.ul-columns-4 .ul-column {
        @include make-sm-column(3);
    }
}

#search {
    position: absolute;
    right: 0;
    top: 120px;
    background: rgba(37,37,37,.8);
    padding: 20px 40px;
}
.search-form label {
    margin: 0;
}

.block-fullwidth,
.block-committees {
    padding: 20px 0;
    background: #f2f2f2;
}
.block-committees {
    padding-top: 0;
}
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.flex-col {
    flex-basis: 290px;
    background: #fff;
    padding: 20px;
    margin: 10px;

    h4 {
        margin-top: 0;
    }
}

.alert {
    padding: 20px 40px;
    h3 {
        margin-top: 0;
        padding-top: 0;
    }
    .btn {
        margin-top: 11.5px;
    }
}
h2 + .alert {
    margin-top: 20px;
}


.select-topic {
    .dropdown-toggle,
    .btn-group {
        width: 100%;
        text-align:left;
    }
}
ul.multiselect-container {
    padding-left:0!important;
}

.ul-cols-3 {
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-items: stretch;
   padding-left: 0!important;
   li:before {
    display:none;
   }
   li {
    flex-basis: 33%;
    flex-grow: 0;
    flex-shrink: 0;
   }
}
.ul-cols-1 {
    padding-left: 0!important;
    li:before {
        display:none;
    }
}