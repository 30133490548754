﻿
.block-contentimage {
    margin: 40px 0;
    
    .block-contentimage-text {
        @include make-sm-column(6);
        @include make-sm-column-offset(0);
    }
    .block-contentimage-image {
        @include make-sm-column(6);
        text-align: center;
    }
}

.block-contentimage-Right {
    .block-contentimage-image {
        float: right!important;
    }
}

/*.block-contentimage-waspworks {  
    .block-contentimage-image {
        margin-top: -90px;
        padding-top: 120px;
        background: url(../images/Wasp-animation-stick.png) no-repeat top center;
        z-index:1000;
    }
}*/