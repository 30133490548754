﻿.carousel {
    border-bottom: 7px solid $brand-light !important;

    .item {
        height: 350px;

        .banner-caption {
            text-align: center;
            color: #fff;
            text-shadow: 2px 2px 5px #000;
            font-size: 40px;
            padding-top: 1em;
            position: relative;
            left: auto;
            right: auto;
        }
        .banner-caption-wrap {
            max-width: 1000px;
            font-size: 24px;
            text-align: center;
            color: #fff;
            text-shadow: 2px 2px 5px #000;
        }
    }
}

    .carousel-indicators-wrapper {
        position: relative;
    }

    .carousel-indicators {
        width: 100%;
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        list-style: none;
        text-align: right;
        margin-bottom: 0!important;
    }
    .carousel-indicators .active {
        margin: 1px;
        width: 20px;
        height: 20px;
        background-color: $brand-dark;
        border-color: $brand-dark;
    }
    .carousel-indicators li {
        opacity: 0.8;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 1px;
        text-indent: -999px;
        border: 1px solid $brand-light;
        background: $brand-light;
        border-radius: 0px;
        cursor: pointer;
    }


@media(max-width: $screen-sm-max) {
    .carousel .item {
        height: 400px;


        .banner-caption {
            font-size: 36px;
        }
        .banner-caption-wrap {
            font-size: 22px;
        }
    }
    .banner-caption-wrap {
        padding: 0 5%;
    }

}

@media(max-width: 900px) {
    .carousel .item {
        height: 350px;


        .banner-caption {
            font-size: 30px;
            padding-top: 1em;

        }
        .banner-caption-wrap {
            font-size: 18px;
        }
    }
}
