.single-profile .main {
    margin-bottom: 0;

    h4 {
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 5px;
    }

    ul.ul-cols-3, ul.ul-cols-1 {
        li {
            margin: 0!important;
        }
    }
}
.profile {

    blockquote p:before,
    blockquote p:after {
        display: none;
    }
}
@media(min-width: $screen-lg-min) {
.profile-top-wrap {
    display: flex;
    align-items: stretch;
    justify-content: left;
    flex-wrap: nowrap;
    margin: 40px 0;
}
}

.profile-vc {
    display: block;
    flex-grow: 1;
    .profile-name {
        margin-top: 0;
    }
}
.profile-image {
    display: block;
    @media(min-width: $screen-lg-min) {
        width: 300px;
    }
    @media(max-width: $screen-md-max) {
        margin-top: 10px;
        img {
        margin-top: 10px !important;
        }
    }
    min-width: 250px;
    flex-grow: 0;
    background: #f2f2f2;
    img {
        display: block;
        margin: 0 auto;
    }
}
.profile-vc {
    margin-left: 40px;
}

.profile-contact-info {
    ul {
        padding: 20px 20px 10px;
    }

    li {
        padding: 5px 0;
        line-height: 20px;
        a {
            /*color: $brand-dark;*/
        }

    }

    .fa {
        color: #ccc;
        font-size: 16px;
        margin-right: 10px;
    }
}

.practice-description {
    margin: 20px 0 40px;
}
@media(min-width: $screen-lg-min) {
.block-addresses {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: nowrap;

    .address-block {
        padding: 40px 0;
        flex-grow: 1;
    }
    .map-block {
        width: 50%;
        flex-grow: 2;
    }
}
}