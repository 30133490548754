.page-header-has-featured {
    background: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /*height: 300px;*/
    max-height: 50vh;
    position: relative;
    border-bottom: none; /*7px solid $brand-light !important;*/
    background: $brand-dark;
    display: flex;
    flex-basis: 200px;
    flex-shrink: 1;
    flex-grow:1;
    flex-flow: row nowrap;
    align-content: center;
    align-items: stretch;
    justify-content: center;
}
.header-photo-wrap {
    margin: 10px 5px;
    flex-shrink: 1;
    flex-grow:1;
    overflow: hidden;
    vertical-align: center;
    text-align: center;

    @media(max-width: $screen-md-max) {
        &:nth-child(2n) {
            display: none;
        }
    }
}
.header-photo {
    display: inline-block;
    width: auto;
    max-height: 260px;
    opacity: 1;
    max-width: 100%;
}

.page-header  {
    padding: 0px 0 0px;
    text-align: left;
    margin: 0px 0;

    background: #f2f2f2;
    h1 {
        padding: 15px 0px 15px;
        font-size: 36px;
        color: $brand-primary;
    }
}

.page-header-has-featured + .page-header  {
    h1 {
        padding: 15px 0px 15px;
        font-size: 36px;
        color: $brand-primary;
    }
}

.home {
    .breadcrumb-container  {
        display: none;
    }

    .page-header {
        background: transparent;
        padding-top: 20px;
        padding-bottom: 0;
        text-align:center;
    }
    .block-content-text {
        font-size: $font-size-base * 1.2;
    }
    .main {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.header-overlay {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(153,116,116,0.4);
}

.block-resources:last-child,
.block-committees:last-child {
    margin-bottom: -50px!important;
}