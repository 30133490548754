.post {
/*
    .wp-post-image {
        float: left;
        display: block;
        margin-right: 50px;
    }
    
    .updated {
        display:block;
        margin-bottom: 20px;
    }
  */  
  .updated {
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    color: inherit;
    margin-bottom: 0.5em;  
    margin-top: 0.25em;
    color: $text-color;  
  }
}

.single .post {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;

    .entry-title {
        font-size: 48px;
        background: #fff;
        padding: 20px 40px;
        text-align: center;
        
        color: $brand-primary;
        line-height: 1;
        margin: 40px 0 0px;
    }
    
    .entry-content {
        background: #fff;
        padding: 30px 40px;
        margin: 40px 0 0px;
        
        > h1:first-child,
        > h2:first-child,
        > h3:first-child {
            margin-top: 0;
        }
    }
    
    
}