﻿ock-cols-container {
    display: block;
    margin: 20px 0 0;
}

.main .block-content.block-resources ~.block-resources {
    margin: 0;
}

.block-events,
.block-community-news,
.block-resources {
    margin-bottom: 0!important;
}

.block-features,
.main .block.block-events,
.block-resources-container,
.block-community-news-container {
    margin: 0px;
    padding: 0px 0 0;
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    background: #f2f2f2;
}
.main .block.block-events {
    padding-bottom: 40px;
    margin-bottom: -40px !important;
}
.block-quote {
    padding: 40px 0;
    background: #f2f2f2;
}

.block-cols-row {
    display: flex;
    margin: 0 -40px;
}

.block-community-news-wrap,
.block-resources-wrap {
    margin: 0 -20px;
}

.block-cols-item {
    display: block;
    float: left;
    padding: 20px 40px;
    width: 50%;
    > h3:first-child {
        margin-top: 0;
    }
}

.block-community-news-item,
.blog-resources-item {
    background: #fff;
    padding: 0px;
    margin: 20px 0;
    overflow: hidden;
    h4 {
        font-size: 18px;
    }
}

.block-community-news-pic,
.blog-resources-pic {
    float: left;
    width: 35%;
    margin-right: 0%;
    max-width: 300px;
}
.block-community-news-pic {
    padding: 30px 20px;
    text-align:center;
    width: 150px;
}

.block-events-item,
.block-community-news-content,
.blog-resources-content {
    padding: 20px 40px 20px 40px;
    float: none;
    width: 100%;
    margin-left: 0;
/*    font-size: 90%;*/
}

.block-community-news-pic + .block-community-news-content,
.blog-resources-pic + .blog-resources-content {
    float: left;
    padding: 20px 20px 20px 0px;
    width: 61%;
    margin-left: 4%;
}
.block-community-news-pic + .block-community-news-content {
    width: calc(100% - 190px);
}

.block-community-news-container,
.block-resources-container {
    h2.block-content-headline {
        font-size: 40px;
        background: transparent;
        padding: 20px 0px 0;
        color: $brand-primary;
        text-align: left;
    }
}

.block-events-container,
.block-community-news-container,
.block-resources-container {
    .event-headline,
    .resource-headline {
        margin: 0 0 20px;
        font-size: 24px;
    }
}

.event-date { margin-bottom: 20px;}

.page-template-template-resources-grid {

    .block-resources-row {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 30%;
        flex-shrink: 0;
        flex-grow: 0;
        justify-content: flex-start;
        margin: 20px -1%;

        .blog-resources-item {
            width: 31%;
            margin: 1%;

            padding-bottom: 50px;
            position:relative;

            .btn {
                position: absolute;
                bottom: 20px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
            }

            .resource-headline a {
                text-decoration: none;
                text-align:center;
                color: $brand-light;

                &:hover {
                    color: darken($brand-light,20%);
                }

            }
            .fa {
                display: block;
                font-size: 100px;
                font-weight: normal;
                text-align: center;
                margin-bottom: 20px;
            }
        }
    }
}