.block-features {
    margin-top: 0!important;
    padding-top: 0!important;
}

.home .block-features {
    padding-top: 40px!important;
}

.block-features-container {
    margin: 0!important;
}

.read-more {
    @extend .btn;
    @extend .btn-primary;
}
.feature  .read-more {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
}

.block-features-flex .post,
.block-features-row .post,
.feature {
    padding: 30px 30px;
    background: #fff;
    position: relative;
    padding-bottom: 80px;
    overflow: hidden;

    .entry-title,
    h3 {
        margin: 0 -10px 10px 0px;
        font-size: 24px;
        color: $header-color;

        small {
            font-size: 20px;
            font-weight: 300;
            text-transform: none;
            display: block;
            color: inherit;
            margin-bottom: 0.5em;
            margin-top: 0.25em;
            color: $text-color;

        }
    }

    .feature-image {
        margin: -31px -40px 30px;
        display: block;
        overflow: hidden;
        height: 1px;
        padding-bottom: 100%;
        position: relative;
        > a,
        > img {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
        }
    }
    img.size-news {
        width: 100%;
    }

    margin-top: 40px;
}

.feature {
    .feature-image {
        height:1px;
        padding: 0 0 100%!important;
        margin:0!important;
        overflow:hidden;
        position:relative;

        img{
            position:absolute;
            opacity: 0.8;
        }
    }
    .feature-description {
        padding: 20px 20px 60px;
        font-size: 13px;
    }
    .read-more {
        bottom: 20px;
    }
    h3 {
        font-size: 20px;
        a {
            color: $brand-light;
        }
        small {
            padding: 0px 0 0;
            font-size: 14px;
            font-family: $font-family-sans-serif;
            a {
                color: $text-color;
            }
        }
    }
    h4 {
        font-size: 16px;
        small {
            font-size: 90%;
            line-height: 1.3;
            margin-top: 0;
        }
    }
}

@media(min-width: $screen-sm-min) {

    .features {
        display: block;
        border-spacing: 20px;
        margin: 0 0px;
    }
    .profiles.features {
        border-spacing: 20px;
        margin-bottom: 0px;
    }

    .block-features-row {
        display: table-row;
    }
    .block-features-flex {
        display: flex;
        align-items: stretch;
        justify-content: left;
        flex-wrap: wrap;
        margin: 0 -1%;
    }
    .block-features-row .post ,
    .block-features-row .feature {
        display: table-cell;
        width: 33.3%;
    }

    .block-features-flex .post,
    .block-features-flex .feature {
        display: block;
        width: 31%;
        margin: 0 1% 20px;
    }

    .features-columns-4 {
        .block-features-row .post,
        .block-features-flex .post,
        .block-features-flex .feature {
            width: 23%;
            padding: 0;

            .feature-image {
                margin: -16px -20px 15px;
                display: block;
            }
        }
    }
    .features-columns-5 {
        .block-features-row .post,
        .block-features-flex .post,
        .block-features-flex .feature {
            width: 19%;
            padding: 0;

            .feature-image {
                margin: -11px -10px 10px;
                display: block;
            }
        }
    }
}
@media(min-width: $screen-md-min) {

    .features {
        display: block;
        border-spacing: 40px;
        margin: 0 -40px;
    }
}