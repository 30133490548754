.btn, input[type=submit], button {
    font-weight: 300;
    padding-left: 2em;
    padding-right: 2em;
}
.btn-large {
    font-size: 20px;
}
.btn-default {
    background: #fff;
    border: 1px solid #ccc;
    color: $text-color;
}

.dropdown-toggle.btn-default:hover {
    background: #fff;
    border: 1px solid #ccc;
    color: $text-color;
}

.pagination {
    padding: 0;
    margin: 0;
}