﻿
// banners
.block-banner {
    z-index: 1;
    position: relative;
    overflow: hidden;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.hero {
        background: #3a3a49;

        .banner-inner {
        top: 10px;
        bottom: 10px;
        }
    }


}
.banner-inner {
    position: absolute;
    left:0;
    right:0;
    top: 0;
    bottom: 0;
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 0;
}
.banner-inner:after {
    position: absolute;
    left:0;
    right:0;
    top: 0;
    bottom: 0;
    content: " ";
    z-index: 1;
    background: rgba(0,0,0,0.25);

}

.banner-caption {
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 5px #000;
    font-size: $font-size-base * 4;
    padding: 0.5em 10% 0.125em;
    z-index: 10;
    position: relative;
}
.banner-text{
    z-index: 10;
    position: relative;
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 5px #000;
    font-size: $font-size-base * 2;
    padding: 1em 10%;
}
.welcome-caption {
    text-align: center;
    /*color: #fff;
    text-shadow: 2px 2px 5px #000;*/
    font-size: $font-size-base * 2.5;
    padding: 0;
    z-index: 10;
    position: relative;
}
.welcome-text {
    position: relative;
    text-align: center;
    /*color: #fff;
    text-shadow: 2px 2px 5px #000;*/
    font-size: $font-size-base * 1.2;
    padding: 1em 10%;
}

.block-banner + .video-link {
    position: absolute;
    left: 50%;
    margin-left: -75px;
    margin-top: -100px;
    z-index:1100;
}


@media (min-width: 1500px) {

    .banner-top-ascending:before {
      top: -60px;
      height: 120px;
      /*-webkit-transform: skewY(-2.5deg);
      -moz-transform: skewY(-2.5deg);
      -ms-transform: skewY(-2.5deg);
      -o-transform: skewY(-2.5deg);
      transform: skewY(-2.5deg);*/

    }
    .banner-top-descending:before {
      top: -60px;
      height: 120px;
    /*  -webkit-transform: skewY(2.5deg);
      -moz-transform: skewY(2.5deg);
      -ms-transform: skewY(2.5deg);
      -o-transform: skewY(2.5deg);
      transform: skewY(2.5deg);   */
    }

    .banner-bottom-ascending,
    .banner-bottom-descending {
        margin-bottom: 120px;
    }

    .banner-bottom-ascending:after {
      bottom: -60px;
      height: 120px;
    }
    .banner-bottom-descending:after {
      bottom: -60px;
      height: 120px;
    }

}